<template>
    <b-container
        class="pt-3 mt-3 pb-0 mb-0 border-bottom">
        <b-row v-if="total_load.length > 1">
            <b-col>
                <h3>{{ this.total_load }}</h3>
                <h6>Beladen</h6>
            </b-col>
            <b-col>
                <h3>{{ this.total_unload }}</h3>
                <h6>Entladen</h6>
            </b-col>
        </b-row>
        <b-row v-else align-content="center"
        >
            <b-skeleton width="100%" height="40px"/>
            <b-skeleton width="100%" height="20px"/>
        </b-row>
    </b-container>
</template>

<script>
export default {
    props: {
        license_plate: String,
        start: String,
        end: String,
    },
    data() {
        return {
            total_load: String,
            total_unload: String,
        }
    },
    async created() {
        // Total Energy Line Chart
        this.$api
            .get("report/energy_total", {
                params: {
                    license_plate: this.license_plate,
                    start: this.start,
                    end: this.end,
                },
            })
            .then((response) => {
                this.total_load = response.data.total_load + ' MWh'
                this.total_unload = response.data.total_unload + ' MWh'
            })
            .catch(() => {
                return [];
            });
    },
}
</script>

<style scoped>
* {
    text-align: center;
}

h3 {
    font-weight: bold;
}
</style>
