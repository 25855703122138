<template>
    <b-row class="mt-5">
        <b-col>
            <h2>Ströme und Temperaturen</h2>
            <line-chart
                v-if="dataSets.length > 0"
                :labels="chartLabels"
                :datasets="dataSets"
                :options="chartOptions"
                :height="200"
            />
            <b-skeleton-img
                v-else
                no-aspect
                height="555px"
            >
            </b-skeleton-img>
        </b-col>
    </b-row>
</template>

<script>
import LineChart from "@/components/LineChart.vue";

const timeFormat = "YYYY-MM-DDTHH:mm:ss"; //2020-10-12T15:00:33

export default {
    components: {
        LineChart,
    },

    props: {
        license_plate: {
            type: String,
        },
        start: {
            type: String,
        },
        end: {
            type: String,
        },
    },

    data() {
        return {
            dataSets: [],
            chartLabels: [],
            chartOptions: {
                elements: {
                    line: {
                        fill: false,
                        tension: 0,
                        borderWidth: 2,
                    },
                    point: {
                        radius: 0,
                    },
                },
                tooltips: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                data.datasets[tooltipItem.datasetIndex].label +
                                ": " +
                                formatNumber(
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index],
                                    2,
                                    ",",
                                    "."
                                )
                            );
                        },
                    },
                },
                hover: {
                    mode: "index",
                    intersect: false,
                },
                animation: {
                    duration: 0,
                },
                responsive: true,
                stacked: false,
                title: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                callback: function (value) {
                                    return value + " °C";
                                },
                                fontColor: "#007bff",
                                beginAtZero: true,
                                suggestedMax: 100,
                                suggestedMin: 0,
                            },
                        },
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "left",
                            id: "y-axis-2",
                            ticks: {
                                callback: function (value) {
                                    return value + " m³/h";
                                },
                                fontColor: "#009e00",
                                beginAtZero: true,
                                suggestedMax: 20,
                                suggestedMin: 0,
                            },
                            // grid line settings
                            gridLines: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "right",
                            id: "y-axis-3",
                            ticks: {
                                callback: function (value) {
                                    return value + " kw";
                                },
                                fontColor: "#000000",
                                beginAtZero: true,
                                suggestedMax: 1200,
                                suggestedMin: -200,
                            },
                            // grid line settings
                            gridLines: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: "time",
                            time: {
                                unit: "hour",
                                parser: timeFormat,
                                displayFormats: {
                                    hour: "DD.MM. HH:mm",
                                },
                            },
                        },
                    ],
                },
            },
        };

        function formatNumber(number, decimalsLength, decimalSeparator, thousandSeparator) {
            var n = number,
                sign = n < 0 ? "-" : "", i = parseInt((n = Math.abs(+n || 0).toFixed(decimalsLength))) + "",
                j = (j = i.length) > 3 ? j % 3 : 0;

            return (
                sign + (j ? i.substr(0, j) + thousandSeparator : "") + i.substr(j)
                    .replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
                (decimalsLength ? decimalSeparator + Math.abs(n - i)
                    .toFixed(decimalsLength).slice(2) : "")
            );
        }
    },
    async created() {
        // Temperature & Flow Chart
        this.$api
            .get("report/temp_flow", {
                params: {
                    license_plate: this.license_plate,
                    start: this.start,
                    end: this.end,
                },
            })
            .then((response) => {
                this.chartLabels = response.data.timecodes;

                this.dataSets = [
                    {
                        label: "Volumenstrom Beladung in m³/h",
                        data: response.data.current_volume_1,
                        borderColor: "#009e00",
                        yAxisID: "y-axis-2",
                    },
                    {
                        label: "Volumenstrom Entladung in m³/h",
                        data: response.data.current_volume_2,
                        borderColor: "#43ffd6",
                        yAxisID: "y-axis-2",
                    },
                    {
                        label: "Leistung Beladung in kW",
                        data: response.data.current_power_1,
                        borderColor: "#000000",
                        yAxisID: "y-axis-3",
                    },
                    {
                        label: "Leistung Entladung in kW",
                        data: response.data.current_power_2,
                        borderColor: "#3e3ef5",
                        yAxisID: "y-axis-3",
                    },
                    {
                        label: "Temperatur Vorlauf in °C",
                        data: response.data.temperature_flow,
                        borderColor: "#ff8800",
                        yAxisID: "y-axis-1",
                    },
                    {
                        label: "Temperatur Rücklauf in °C",
                        data: response.data.temperature_return_flow,
                        borderColor: "#ea17ff",
                        yAxisID: "y-axis-1",
                    },
                    {
                        label: "Delta Vor- / Rücklauf in °C",
                        data: response.data.temperature_delta,
                        borderColor: "#b083ff",
                        yAxisID: "y-axis-1",
                    },
                ];
            })
            .catch(() => {
                return [];
            });
    },
};
</script>

<style scoped>
</style>
