<template>
    <b-row class="mt-5">
        <b-col>
            <h2>Energien</h2>
            <bar-chart
                v-if="dataSets.length > 0"
                :datasets="dataSets"
                :height="100"
                :labels="chartLabels"
                :options="chartOptions"
            />
            <b-skeleton-img
                v-else
                no-aspect
                height="277px"
            >
            </b-skeleton-img>
        </b-col>
    </b-row>
</template>

<script>
import BarChart from "@/components/BarChart.vue";


export default {
    components: {
        BarChart,
    },

    props: {
        license_plate: {
            type: String,
        },
        start: {
            type: String,
        },
        end: {
            type: String,
        },
    },

    data() {
        return {
            dataSets: [],
            chartLabels: [],
            chartOptions: {
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return data.datasets[tooltipItem.datasetIndex].label + ": " + formatNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 2, ',', '.');
                        }
                    }
                },
                hover: {
                    mode: 'index',
                    intersect: false
                },
                animation: {
                    duration: 0
                },
                responsive: true,
                stacked: false,
                title: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                        display: true,
                        position: "left",
                        ticks: {
                            callback: function (value) {
                                return formatNumber(value, 2, ',', '.') + ' MWh';
                            },
                            beginAtZero: true,
                            fontColor: "#009e01"
                        }
                    }]
                }
            }
        };

        function formatNumber(number, decimalsLength, decimalSeparator, thousandSeparator) {
            var n = number,
                sign = n < 0 ? "-" : "", i = parseInt((n = Math.abs(+n || 0).toFixed(decimalsLength))) + "",
                j = (j = i.length) > 3 ? j % 3 : 0;

            return (
                sign + (j ? i.substr(0, j) + thousandSeparator : "") + i.substr(j)
                    .replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
                (decimalsLength ? decimalSeparator + Math.abs(n - i)
                    .toFixed(decimalsLength).slice(2) : "")
            );
        }
    },
    async created() {
        // Total Energy Bar Chart
        this.$api
            .get("georeport", {
                params: {
                    license_plate: this.license_plate,
                    start: this.start,
                    end: this.end,
                },
            })
            .then((response) => {
                this.chartLabels = response.data.labels;

                this.dataSets = [
                    {
                        label: 'Beladene Energiemenge in MWh',
                        data: response.data.loading,
                        backgroundColor: '#b083ff',
                        hoverBackgroundColor: '#b083ff',
                    },
                    {
                        label: 'Entladene Energiemenge in MWh' + '',
                        data: response.data.unloading,
                        backgroundColor: '#ea17ff',
                        hoverBackgroundColor: '#ea17ff',
                    },
                ];
            })
            .catch(() => {
                return [];
            });
    },
};
</script>

<style scoped>
</style>
