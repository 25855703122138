<template>
    <b-container class="reports" fluid="" style="margin-left: 250px; width: 80%;">
        <b-container
            class="pt-3 pb-0 mb-0 border-bottom border-dark">
            <b-row>
                <b-col>
                    <h1 class="h2">Reports</h1>
                </b-col>

                <b-col cols="4">
                    <b-form-group
                        label="Start:"
                        label-for="start-datepicker"
                        label-cols="2"
                        label-class="pt-1"
                        label-size="lg"
                    >
                        <b-form-datepicker
                            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                            id="start-datepicker"
                            locale="de"
                            @input="refresh_key++; start += ' 00:00'"
                            v-model="start"
                        >
                        </b-form-datepicker>
                    </b-form-group>
                </b-col>

                <b-col cols="4">
                    <b-form-group
                        label="Ende:"
                        label-for="end-datepicker"
                        label-cols="2"
                        label-class="pt-1"
                        label-size="lg"
                    >
                        <b-form-datepicker
                            :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                            id="end-datepicker"
                            locale="de"
                            @input="refresh_key++; end += ' 23:59'"
                            v-model="end"
                        >
                        </b-form-datepicker>
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-select
                        v-model="license_plate"
                        :options="license_plate_list"
                        @input="refresh_key++"
                    >
                    </b-form-select>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="license_plate_list.length > 0">
            <totalenergy-stats
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            >
            </totalenergy-stats>
            <totalenergybar-chart
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            >
            </totalenergybar-chart>
            <totalenergyline-chart
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            >
            </totalenergyline-chart>
            <tempflow-chart
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            >
            </tempflow-chart>
            <temperature-chart
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            >
            </temperature-chart>
            <battery-chart
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            ></battery-chart>
            <pressure-chart
                :license_plate="license_plate"
                :start="start"
                :end="end"
                :key="refresh_key"
            ></pressure-chart>
        </b-container>
    </b-container>
</template>

<script>
import moment from "moment"

moment().format("YYYY-MM-DD");
import PressureChart from "@/components/charts/PressureChart.vue";
import BatteryChart from "@/components/charts/BatteryChart.vue";
import TemperatureChart from "@/components/charts/TemperatureChart.vue";
import TempflowChart from "@/components/charts/TempflowChart.vue";
import TotalEnergyLineChart from "@/components/charts/TotalEnergyLineChart.vue";
import TotalEnergyBarChart from "@/components/charts/TotalEnergyBarChart.vue";
import TotalEnergyStats from "@/components/charts/TotalEnergyStats.vue";

export default {
    components: {
        "pressure-chart": PressureChart,
        "battery-chart": BatteryChart,
        "temperature-chart": TemperatureChart,
        "tempflow-chart": TempflowChart,
        "totalenergyline-chart": TotalEnergyLineChart,
        "totalenergybar-chart": TotalEnergyBarChart,
        "totalenergy-stats": TotalEnergyStats,
    },
    data() {
        return {
            license_plate_list: [],
            license_plate: "",
            start: "", // "2020-10-12 12:00",
            end: "", // "2020-10-12 18:00",
            refresh_key: 1,
        };
    },
    created() {
        this.$api
            .get("container/license_plates", {
                params: {},
            })
            .then((response) => {
                this.license_plate_list = response.data;
                this.license_plate = this.license_plate_list[0];
            })
            .catch(() => {
                return [];
            });

        this.start = moment().add(-1, "week").format("YYYY-MM-DD") + " 00:00";
        this.end = moment().format("YYYY-MM-DD") + " 23:59";
    }
};
</script>

<style>
</style>
