<template>
    <b-row class="mt-5">
        <b-col>
            <h2>Druck</h2>
            <line-chart
                v-if="dataSets.length > 0"
                :labels="chartLabels"
                :datasets="dataSets"
                :options="chartOptions"
                :height="100"
            />
            <b-skeleton-img
                v-else
                no-aspect
                height="277px"
            >
            </b-skeleton-img>
        </b-col>
    </b-row>
</template>

<script>
import LineChart from "@/components/LineChart.vue";

const timeFormat = "YYYY-MM-DDTHH:mm:ss"; //2020-10-12T15:00:33

export default {
    components: {
        LineChart,
    },

    props: {
        license_plate: {
            type: String,
        },
        start: {
            type: String,
        },
        end: {
            type: String,
        },
    },

    data() {
        return {
            dataSets: [],
            chartLabels: [],
            chartOptions: {
                elements: {
                    line: {
                        fill: false,
                        tension: 0,
                        borderWidth: 2,
                    },
                    point: {
                        radius: 0,
                    },
                },
                tooltips: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                        label: function (tooltipItem, data) {
                            return (
                                data.datasets[tooltipItem.datasetIndex].label +
                                ": " +
                                formatNumber(
                                    data.datasets[tooltipItem.datasetIndex]
                                        .data[tooltipItem.index],
                                    2,
                                    ",",
                                    "."
                                )
                            );
                        },
                    },
                },
                hover: {
                    mode: "index",
                    intersect: false,
                },
                animation: {
                    duration: 0,
                },
                responsive: true,
                stacked: false,
                title: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "left",
                            ticks: {
                                callback: function (value) {
                                    return (
                                        formatNumber(value, 2, ",", ".") +
                                        " bar"
                                    );
                                },
                                suggestedMin: 0,
                                fontColor: "#9e2737",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: "time",
                            time: {
                                unit: "hour",
                                parser: timeFormat,
                                displayFormats: {
                                    hour: "DD.MM. HH:mm",
                                },
                            },
                        },
                    ],
                },
            },
        };

        function formatNumber(number, decimalsLength, decimalSeparator, thousandSeparator) {
            var n = number,
                sign = n < 0 ? "-" : "", i = parseInt((n = Math.abs(+n || 0).toFixed(decimalsLength))) + "",
                j = (j = i.length) > 3 ? j % 3 : 0;

            return (
                sign + (j ? i.substr(0, j) + thousandSeparator : "") + i.substr(j)
                    .replace(/(\d{3})(?=\d)/g, "$1" + thousandSeparator) +
                (decimalsLength ? decimalSeparator + Math.abs(n - i)
                    .toFixed(decimalsLength).slice(2) : "")
            );
        }
    },
    async created() {
        // Pressure Chart
        this.$api
            .get("report/pressure", {
                params: {
                    license_plate: this.license_plate,
                    start: this.start,
                    end: this.end,
                },
            })
            .then((response) => {
                this.chartLabels = response.data.timecodes;

                this.dataSets = [
                    {
                        label: "Druck Salzraum in bar",
                        data: response.data.pressure_1,
                        borderColor: "#9e2737",
                    },
                    {
                        label: "Druck Wasserraum in bar",
                        data: response.data.pressure_2,
                        borderColor: "#009e04",
                    },
                ];
            })
            .catch(() => {
                return [];
            });
    },
};
</script>

<style scoped>
</style>
