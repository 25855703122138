<template>
    <b-row class="mt-5">
        <b-col>
            <h2>Temperaturen</h2>
            <line-chart
                v-if="dataSets.length > 0"
                :labels="chartLabels"
                :datasets="dataSets"
                :options="chartOptions"
                :height="200"
            />
            <b-skeleton-img
                v-else
                no-aspect
                height="555px"
            >
            </b-skeleton-img>
        </b-col>
    </b-row>
</template>

<script>
import LineChart from "@/components/LineChart.vue";

const timeFormat = "YYYY-MM-DDTHH:mm:ss"; //2020-10-12T15:00:33

export default {
    components: {
        LineChart,
    },

    props: {
        license_plate: {
            type: String,
        },
        start: {
            type: String,
        },
        end: {
            type: String,
        },
    },

    data() {
        return {
            dataSets: [],
            chartLabels: [],
            chartOptions: {
                elements: {
                    line: {
                        fill: false,
                        tension: 0,
                        borderWidth: 2,
                    },
                    point: {
                        radius: 0,
                    },
                },
                tooltips: {
                    mode: "index",
                    intersect: false,
                },
                hover: {
                    mode: "index",
                    intersect: false,
                },
                animation: {
                    duration: 0,
                },
                responsive: true,
                stacked: false,
                title: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            type: "linear", // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
                            display: true,
                            position: "left",
                            ticks: {
                                callback: function (value) {
                                    return value + " °C";
                                },
                                fontColor: "#007bff",
                                beginAtZero: true,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: "time",
                            time: {
                                unit: "hour",
                                parser: timeFormat,
                                displayFormats: {
                                    hour: "DD.MM. HH:mm",
                                },
                            },
                        },
                    ],
                },
            },
        };
    },
    async created() {
        // Temperature Chart
        this.$api
            .get("report/temperature", {
                params: {
                    license_plate: this.license_plate,
                    start: this.start,
                    end: this.end,
                },
            })
            .then((response) => {
                this.chartLabels = response.data.timecodes;

                this.dataSets = [
                    {
                        label: "Temperatur 1 in °C",
                        data: response.data.temperature_1,
                        borderColor: "#009e00",
                    },
                    {
                        label: "Temperatur 2 in °C",
                        data: response.data.temperature_2,
                        borderColor: "#007bff",
                    },
                    {
                        label: "Temperatur 3 in °C",
                        data: response.data.temperature_3,
                        borderColor: "#000000",
                    },
                    {
                        label: "Temperatur 4 in °C",
                        data: response.data.temperature_4,
                        borderColor: "#ff8800",
                    },
                    {
                        label: "Temperatur 5 in °C",
                        data: response.data.temperature_5,
                        borderColor: "#ff7790",
                    },
                    {
                        label: "Temperatur Vorlauf in °C",
                        data: response.data.temperature_6,
                        borderColor: "#6d4abe",
                    },
                    {
                        label: "Temperatur Rücklauf in °C",
                        data: response.data.temperature_7,
                        borderColor: "#c44508",
                    },
                ];
            })
            .catch(() => {
                return [];
            });
    },
};
</script>

<style scoped>
</style>
